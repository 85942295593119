import React from "react";
import projects from "../data/projects.json";
import "./Projects.css";

function Projects() {
  return (
    <div className="projects-section">
      <h1 className="projects-title">Projects</h1>
      <div className="projects-grid">
        {projects.map((project) => (
          <div key={project.id} className="project-card">
            {/* Tag Badge */}
            <span className="project-tag">{project.tag}</span>
            <h2 className="project-title">{project.title}</h2>
            <p className="project-description">{project.description}</p>
            <p className="project-tech">
              <strong>Technologies:</strong> {project.tech.join(", ")}
            </p>
            <div className="project-links">
              {/* Render GitHub button only if the link is not empty */}
              {project.github && (
              <a
                href={project.github}
                target="_blank"
                rel="noopener noreferrer"
                className="project-link github-link"
              >
                GitHub
              </a>
              )}

              {/* Render Live Demo button only if the link is not empty */}
              {project.liveDemo && (
              <a
                href={project.liveDemo}
                target="_blank"
                rel="noopener noreferrer"
                className="project-link demo-link"
              >
                Additional Resources
              </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
// src/pages/Home.js
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faDatabase, faProjectDiagram, faChartBar } from "@fortawesome/free-solid-svg-icons";
import "./Home.css";
import ContactPopup from "./Contact";
import profileImage from "./IMG_4842.jpg"; // Adjust the path based on your project structure
import { Link } from "react-router-dom"; // Import Link from react-router-dom



function Home() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleOpenPopup = () => setIsPopupOpen(true);
    const handleClosePopup = () => setIsPopupOpen(false);

  return (
    <div className="home">
      {/* Main Section */}
      <section className="main-section">
        <div className="info">
          <h1>JuanMa Rozas, Ph.D.</h1>
          <h2 className="subtitle">Advisory and Consulting</h2>
          <p className="motivation">Streamline your processes, make the most of your data, 
            and uncover valuable insights — all to drive digital transformation and empower smarter business decisions.</p>
        </div>
        <div className="picture">
          {/* Add your picture */}
          <img src={profileImage} alt="Your Name" />
        </div>
      </section>

      {/* Pillars Section */}
      <section className="pillars">
        <h2>The Four Pillars</h2>
        <p>Through my experience, I’ve learned that success rests on what I call <em>The Four Pillars</em>: <strong>Digital Transformation</strong>, <strong>Data Management</strong>, <strong>Process Modeling</strong>, and <strong>Business Intelligence</strong>. 
            These pillars form the backbone of a smooth and effective data-driven transformation, helping organizations unlock the full potential 
            of their data while staying aligned with their strategic goals.</p>
        <div className="pillar-columns">
          <div className="pillar">
            <FontAwesomeIcon icon={faCogs} className="pillar-icon" />
            <h3>Digital Transformation</h3>
            <p>Driving change with cutting-edge technology and innovative solutions.</p>
            <Link to="/projects#2" className="pillar-link">
            View Related Projects
            </Link>
          </div>
          <div className="pillar">
            <FontAwesomeIcon icon={faDatabase} className="pillar-icon" />
            <h3>Data Management</h3>
            <p>Organizing, storing, and leveraging data for actionable insights.</p>
            <Link to="/projects#3" className="pillar-link">
            View Related Projects
            </Link>
          </div>
          <div className="pillar">
            <FontAwesomeIcon icon={faProjectDiagram} className="pillar-icon" />
            <h3>Process Modeling</h3>
            <p>Streamlining operations through effective workflows and modeling.</p>
            <Link to="/projects#3" className="pillar-link">
            View Related Projects
            </Link>
          </div>
          <div className="pillar">
            <FontAwesomeIcon icon={faChartBar} className="pillar-icon" />
            <h3>Business Intelligence</h3>
            <p>Turning data into valuable business insights and strategies.</p>
            <Link to="/projects#1" className="pillar-link">
            View Related Projects
            </Link>
          </div>
        </div>
      </section>

      {/* Get in Touch Section */}
      <section className="get-in-touch">
        <h2>Get in Touch</h2>
        <p>Interested in collaborating or learning more about my work? Feel free to reach out!</p>
        <button onClick={handleOpenPopup} className="contact-button">
          Contact Me
        </button>
      </section>

      {/* Contact Pop-Up */}
      <ContactPopup isOpen={isPopupOpen} onClose={handleClosePopup} />
    </div>
  );
}

export default Home;
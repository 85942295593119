import React from "react";
import "./Resumee.css"; // Optional: Import CSS for styling
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";


// Header Component
const Header = () => (
  <header className="resume-header">
    <h1>Juan Manuel Rozas Andaur, Ph.D.</h1>
    <p>Santiago, Chile</p>
    <p>
      jm@jmrozas.com |
        <a href="https://linkedin.com/in/juanrozasa" target="_blank" rel="noreferrer">
          <LinkedInIcon fontSize="small" style={{ verticalAlign: "middle" }} />
        </a>{" "}
        <a href="https://github.com/JuanMaRozas" target="_blank" rel="noreferrer">
          <GitHubIcon fontSize="small" style={{ verticalAlign: "middle" }} />
        </a>
    </p>
  </header>
);

// Section Component
const Section = ({ title, children }) => (
  <section className="resume-section">
    <h2>{title}</h2>
    {children}
  </section>
);

// Subsection Component
const Subsection = ({ title, role, date, children }) => (
  <div className="resume-subsection">
    <h3>{title}</h3>
    <h4>
      <em>{role}</em> <span className="resume-date">{date}</span>
    </h4>
    <ul>{children}</ul>
  </div>
);

// Experience Item Component
const ExperienceItem = ({ children }) => <li>{children}</li>;

// Main Resume Component
const Resume = () => (
  <div className="resume">
    <Header />

    <Section title="Summary">
    <p
    style={{
        textAlign: "justify",
        marginBottom: "0rem",
        lineHeight: "1.2",
    }}
    >
    I’m a strategic data and analytics engineer with a PhD in Complex Systems Engineering and over 10 years of experience 
    transforming businesses through innovative data strategies and advanced analytics. My expertise spans applied data 
    analysis and strategy development in industries like retail, manufacturing, agribusiness, finance, and SaaS. 
    I have led impactful initiatives in sales forecasting, operational efficiency, mergers and acquisitions (M&A), and 
    debt restructuring, driving success and organizational growth.
    </p>
    <p
    style={{
        textAlign: "justify",
        marginBottom: "1.5rem",
        lineHeight: "1.2",
    }}
    >
    My experience includes process modeling, business intelligence, and machine learning, with a proven track record of 
    leveraging DAMA standards to enhance data governance and enable smarter decision-making. From designing data lakes to serve 
    as a single source of truth (SSoT) to developing predictive models and creating strategic, operational, and analytics dashboards 
    and reports, I’m committed to delivering impactful results. Additionally, I’m certified by Harvard Business School CORe, 
    with advanced knowledge in business analytics and financial strategy.

    </p>
    </Section>

    <Section title="Core Skills">
    <li style={{ marginBottom: "0.5rem", listStylePosition: "outside" }}><strong>Data Management:</strong> DAMA standards, data strategy , data governance, data architecture, data quality assurance </li>
    <li style={{ marginBottom: "0.5rem", listStylePosition: "outside" }}><strong>Business Intelligence & Analytics:</strong> Predictive modeling, dashboards, cloud platforms (AWS, GCP) </li>
    <li style={{ marginBottom: "0.5rem", listStylePosition: "outside" }}><strong>Process Optimization:</strong> Workflow modeling, cross-functional collaboration, stakeholder alignment </li>
    <li style={{ marginBottom: "0.5rem", listStylePosition: "outside" }}><strong>Technical Proficiency:</strong> Python, R, SQL, Tableau, Power BI, AWS QuickSight, Hadoop, Spark </li>
    <li style={{ marginBottom: "0.5rem", listStylePosition: "outside" }}><strong>Languages:</strong> Fluent in English (TOEIC 900+), native in Spanish </li>
    </Section>
    
    <Section title="Experience">
      <Subsection title="Head of Data Management & Analytics" role="Lemontech, Santiago, Chile" date="Sep 2022 – Present">
        <ExperienceItem>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Established a unified Data Lake as the Single Source of Truth (SSOT), 
                    enhancing data reliability across departments by aligning governance practices with DAMA standards.
                </li>
            </ul>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Led the optimization of CRM (HubSpot) and ERP (NetSuite) systems, 
                    driving a projected 30% increase in operational efficiency.
                </li>
            </ul>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Designed and implemented dashboards and reports that supported strategic 
                    decision-making at the Board and C-level, aligning data strategies with business objectives.
                </li>
            </ul>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Designed and implemented operational dashboards and reports for marketing, sales, customer success, 
                    and finance teams, integrating KPIs and metrics to monitor daily operations, enhance decision-making, 
                    and drive actionable improvement plans.
                </li>
            </ul>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Created advanced client usage segmentation tools to 
                    empower Customer Success teams, successfully achieving churn reduction goals.
                </li>
            </ul>
        </ExperienceItem>
      </Subsection>

      <Subsection title="Chief Data Officer (CDO)" role="Alto La Cruz, Santiago, Chile" date="Jun 2018 – Aug 2022">
        <ExperienceItem>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Developed and implemented a comprehensive four-year strategic plan to drive company growth and revaluation. 
                This transformation involved transitioning the organization into a data-driven company, 
                leading to a 50% revenue growth with sales reaching $33M USD and EBITDA increasing from 5% to 12%.
                </li>
            </ul>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Directed the development of an integrated database that unified sales, operations, 
                    and customer records, providing a centralized platform for strategic decision-making and 
                    actionable insights.
                </li>
            </ul>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Leveraged data analytics to identify consumer behavior trends in the FMCG market, 
                    developing dashboards and reports to supply, sales, marketing, 
                    and operations.
                </li>
            </ul>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Designed a predictive model to identify stockouts in retail stores, 
                    achieving a 15% improvement in product availability and streamlining point of sales operations.
                </li>
            </ul>
        </ExperienceItem>
      </Subsection>

      <Subsection title="Business Intelligence (BI) Manager" role="South Am Freeze Dry, Santiago, Chile" date="Aug 2016 – Jun 2018">
        <ExperienceItem>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Used business intelligence to drive sales and marketing strategies, achieving a 25% revenue increase. 
                    Generated $1M in new revenue and launched novel retail products in Chile, Mexico, and the USA with major retailers 
                    like Walmart and Amazon.
                </li>
            </ul>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Prepared and presented comprehensive business performance reports, including financials, sales and market metrics, 
                    and customer insights, to Boards of Directors and C-level executives across facilities in Chile, the USA, 
                    and Mexico.
                </li>
            </ul>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>As part of the leadership team for the M&A data room process, ensured the accuracy, 
                    reliability, and integrity of reports, enabling informed decision-making throughout due diligence.
                </li>
            </ul>
        </ExperienceItem>
      </Subsection>

      <Subsection title="Business Intelligence (BI) Analyst" role="South Am Freeze Dry, Santiago, Chile" date="Sep 2015 – Jul 2016">
        <ExperienceItem>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Utilized analytics and retail systems to identify market trends and KPIs, 
                    achieving an 8.7% sales increase and 10% growth in brand awareness.
                </li>
            </ul>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Deployed A/B testing and automation to improve campaign performance, 
                    boosting sales conversions by 5% and social media engagement by 6.5%.
                </li>
            </ul>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Partnered with teams to implement integrated marketing strategies, 
                    driving customer loyalty and enhancing engagement through data-informed decisions.
                </li>
            </ul>
        </ExperienceItem>
      </Subsection>

      <Subsection title="Data Analyst" role="Guital&Partners, Santiago, Chile" date="Aug 2014 – Aug 2015">
        <ExperienceItem>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Analyzed consumer behavior, market trends, and competitive data to develop marketing strategies, 
                    driving a 50% improvement in sales performance through targeted online and in-store campaigns.
                </li>
            </ul>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Monitored merchandise distribution, pricing, and inventory levels across multiple locations 
                    using data from Retail Link and B2B systems, ensuring alignment with brand standards.
                </li>
            </ul>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Collaborated with media and advertising agencies to launch store-specific campaigns and 
                    promotions, enhancing brand awareness and strengthening customer relationships.
                </li>
            </ul>
        </ExperienceItem>
      </Subsection>
      {/* Repeat for other roles */}
    </Section>

    <Section title="Teaching Experience">      
      <Subsection title="Data Science Master’s Program Lecturer - Part time" role="Universidad de Las Américas, Santiago, Chile" date="Sep 2022 – Present">
        <ExperienceItem>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Designed and delivered a postgraduate course on advanced data science topics, including cloud computing, 
                    machine learning, and data visualization. Guided students through practical, industry-focused applications, 
                    preparing them for data science roles in technology, industrial, and retail sectors.
                </li>
            </ul>
        </ExperienceItem>
      </Subsection>

      <Subsection title="Science Faculty Guest Lecturer - Part time" role="Universidad de Chile, Santiago, Chile" date="Jan 2015 – Jan 2017">
        <ExperienceItem>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Business Intelligence and Applied Data Analytics
                </li>
            </ul>
        </ExperienceItem>
      </Subsection>

      <Subsection title="Engineering & Science Faculty Lecturer - Part time" role="Universidad Adolfo Ibañez, Santiago, Chile" date="Jul 2014 – Dec 2014">
      <ExperienceItem>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Engineering Master’s Program Lecturer
                </li>
            </ul>
            <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
                <li>Engineering Bachelor’s Program Lecturer 
                </li>
            </ul>
        </ExperienceItem>
      </Subsection>
    </Section>

    <Section title="Education">
        <p style={{ marginBottom: "0.5rem", listStylePosition: "outside" }}>
        <strong> PhD in Complex Systems Engineering:</strong> Universidad Adolfo Ibañez, Chile.  <span className="resume-date">2022</span> 
        <p style={{ textAlign: "justify", marginTop: "0.5rem", fontSize: "0.9rem" }}>Specialized in Applied Data Science and Machine Learning, focusing on developing advanced models and analytical 
            frameworks to solve complex organizational challenges, enhance decision-making, and drive innovation across 
            business operations.
        </p>
        </p>

        <p style={{ marginBottom: "0.5rem", listStylePosition: "outside" }}>
        <strong> Engineering:</strong> Universidad de Chile, Chile.  <span className="resume-date">2011</span> 
        <p style={{ textAlign: "justify", marginTop: "0.5rem", fontSize: "0.9rem" }}>Specialization in Mathematics and Innovation: 
            Developed strong analytical and problem-solving skills by applying advanced mathematical concepts to drive 
            innovative solutions in engineering projects
        </p>
        </p>
    </Section>

    <Section title="Certifications">
      <ul>
        <ExperienceItem>
          <strong>Harvard Business School Online:</strong> CORe Credential of Readiness (Business
          Analytics, Economics for Managers, Financial Accounting) – 2020
        </ExperienceItem>
        <ExperienceItem>
          <strong>BRC Global Standard:</strong> Internal Audit Certification – 2019
        </ExperienceItem>
      </ul>
    </Section>

    <Section title="Involvement">
        <div style={{ marginBottom: "1.5rem" }}>
        <h3>Innovation Project Administration & Fundraising</h3>
        <p>
        <strong>VIU & FIA</strong> (2014)
        </p>
        <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
        <li>
        Led an innovative project utilizing big data and advanced analytics to solve critical industrial challenges, optimizing processes while achieving a positive environmental impact.
        </li>
        <li>
            Raised $0.5M in funding through strategic investments and public grants.
        </li>
        </ul>
    </div>

    <div style={{ marginBottom: "1.5rem" }}>
        <h3>Participation in Innovation Congresses & Venture Capital Conventions</h3>
        <p>
        <strong>Corfo & InvestChile</strong> (2017)
        </p>
        <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
        <li>
            Represented Chilean Government initiatives at international biotechnology and venture capital conventions, including the 2017 BIO International Convention and RESI (Redefining Early Stage Investments).
        </li>
        <li>
        Built strategic alliances with industry leaders and investors to develop innovative projects.
        </li>
        </ul>
    </div>

    <div style={{ marginBottom: "1.5rem" }}>
        <h3>Participation in Innovation Trade Shows</h3>
        <p>
        <strong>IFT & Natural Products Expo West</strong> (2015–2018)
        </p>
        <ul style={{ paddingLeft: "1.5rem", listStyleType: "disc" }}>
        <li>
            Represented a food tech company at major U.S. trade shows, managing booths, engaging with clients, showcasing innovative products, and building industry connections.
        </li>
        <li>
        Participated in digital innovation presentations to drive the implementation of digital transformation and big data initiatives within the organization.
        </li>
        </ul>
    </div>
    </Section>
  </div>
);

export default Resume;
// src/components/Header.js
import React from "react";
import { NavLink } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import "./Header.css";
import RssFeedIcon from "@mui/icons-material/RssFeed"; // Using MUI RSS Icon to represent Medium

function Header() {
  return (
    <header>
      <nav>
        <ul>
          <li>
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/projects"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Projects
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/resumee"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Resumee
            </NavLink>
          </li>
        </ul>
        <ul className="social-links">
          <li>
            <a
              href="https://www.linkedin.com/in/juanrozasa/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon fontSize="large" />
            </a>
          </li>
          <li>
            <a
              href="https://github.com/JuanMaRozas"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitHubIcon fontSize="large" />
            </a>
          </li>
          <li>
            <a
              href="https://medium.com/@jm_30387"
              target="_blank"
              rel="noopener noreferrer"
            >
              <RssFeedIcon fontSize="large" /> {/* Representing Medium */}
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
import React, { useState } from "react";
import "./Contact.css"; // Add styles for the modal
import emailjs from "@emailjs/browser";

function ContactPopup({ isOpen, onClose }) {
  const [formData, setFormData] = useState({ name: "", email: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceID = "service_ko5elc5";
    const templateID = "template_z7xcu9b";
    const publicKey = "15e5p9QZv0tfbYmRW";

    const templateParams = {
      name: formData.name,
      email: formData.email,
    };

    emailjs
      .send(serviceID, templateID, templateParams, publicKey)
      .then(() => {
        alert("Your message has been sent!");
        setFormData({ name: "", email: "" });
        onClose(); // Close the modal after submission
      })
      .catch((err) => {
        console.error("Failed to send email:", err);
        alert("Failed to send the message. Please try again later.");
      });
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Contact Us</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactPopup;